.questionTypeSelectorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.rulesList {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 40px;
  padding-left: 40px;
  border-left: 2px solid var(--text-color);
  border-image: linear-gradient(to bottom, #000 50%, transparent 50%) 2 100%;
}

.buttonTitle {
  color: var(--text-color);
  font-size: 15px;
  line-height: 24px;
  font-family: 'Hind', sans-serif;
}

.iconsContainer {
  display: flex;
  margin-top: 20px;
}

.questionTypeText {
  padding-top: 20px;
  color: var(--text-color);
  /*padding-left: 40px;*/
  max-width: 250px;
}

.rulesList :global(.rulesListItemGlobal) button {
  background-color: var(--button-primary-pressed-15);
  color: var(--text-color);
}

.rulesList :global(.rulesListItemGlobal) button:hover {
  background-color: var(--theme-accent);
}

.rulesList :global(.rulesListItemGlobal) button:focus,
.rulesList :global(.rulesListItemGlobal) button:hover,
.rulesList :global(.rulesListItemGlobal) button:not(:disabled):not(.disabled).active,
.rulesList :global(.rulesListItemGlobal) button:not(:disabled):not(.disabled):active:focus {
  background-color: var(--theme-accent);
}

.rulesList :global(.rulesListItemGlobal .buttonIconActiveGlobal) {
  background-color: var(--theme-accent);
}

.questionTypeSelectorContainer button {
  transition: 0.3s all;
}

.questionTypeSelectorContainer button:first-child {
  margin-right: 20px;
}

.notActive {
  background-color: #fcf9d9;
  border-color: #fcf9d9;
}

@media (max-width: 767px) {
  .rulesList {
    margin: 0;
    padding: 0;
  }

  .rulesList {
    border-left: unset;
    border-image: unset;
    border-top: 2px solid var(--text-color);
  }

  .buttonTitle {
    margin-top: 10px;
    text-align: left;
  }

  .questionTypeText {
    text-align: left;
  }
}
