.possessionNoteContainer {
  display: flex;
  align-items: center;
  width: 320px;
  margin-bottom: 20px;
}

.text {
  margin: 0;
  padding: 0 0 0 5px;
  text-align: left;
}
