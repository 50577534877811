.categoryContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rulesList {
  display: flex;
  margin: 20px 0 20px;
}

.rulesListItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.rulesListItem:not(:last-child) {
  margin-right: 26px;
}

.rulesListItemBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--whiteColor);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.rulesListItemBg > i {
  font-size: 48px;
}

.rulesListItemText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.rulesListItemBadge {
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
}

.titleText {
  margin: 0;
  white-space: nowrap;
}

@media (max-width: 1199px) {
  .rulesList {
    justify-content: center;
  }

  .rulesListItemBg {
    background-color: var(--additional-light-blue);
  }

  .rulesListItemBadge {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .rulesListItemBg {
    width: 60px;
    height: 60px;
  }

  .rulesListItemBg > i {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .categoryContainer {
    flex-direction: column;
  }
}
